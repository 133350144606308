
import GLightbox from 'glightbox';

const lightbox = GLightbox({
  closeOnOutsideClick: true,
  touchNavigation: true,
  touchFollowAxis: false,
  zoomable: true
});

lightbox.on('open', () => {
  let gbody= document.querySelector('#glightbox-body');
  if (!gbody) {
    return;
  }

  gbody.addEventListener("touchend", function(event) {
    if (lightbox && event && event.target instanceof HTMLElement && !event.target.closest('.gslide-media')) {
      lightbox.close();
      event.preventDefault();
      event.stopPropagation();
    }
    return true;
  });
  gbody.addEventListener("click", function(event) {
    if (lightbox && event && event.target instanceof HTMLElement && !event.target.closest('.gslide-media')) {
      lightbox.close();
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    return true;
  });

});
window['GLightbox'] = GLightbox;
export default lightbox;
