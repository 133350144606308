export default function(data){

  return {
    initSlider: async function($refs){

      const { Swiper, Scrollbar, Navigation, FreeMode } = await import('swiper');
      Swiper.use([Scrollbar, Navigation, FreeMode]);


      let el = $refs.swiper;
      let scrollbarEl = $refs.scrollbar;
      const slideCount = data.slide_count;
      let next = $refs.next;
      let prev = $refs.prev;
      const slidesPerViewXS = parseInt(data.slides_per_view.xs);
      const slidesPerViewSM = parseInt(data.slides_per_view.sm);
      const slidesPerViewMD = parseInt(data.slides_per_view.md);
      const slidesPerViewLG = parseInt(data.slides_per_view.lg);
      let freeModeOnLg = slideCount > 4 ? true : false;
      const swiper = new Swiper(el, {
        slidesPerView: Math.min(slidesPerViewXS + 0.2, slideCount),
        spaceBetween: 20,
        freeMode: {
          enabled: true,
          sticky: false
        },
        scrollbar: {
          el: scrollbarEl,
          draggable: true,
        },
        lazy: {
          //  tell swiper to load images before they appear
          loadPrevNext: true,
          // amount of images to load
          loadPrevNextAmount: 6,
        },
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        breakpoints: {
          480: {
            slidesPerView: Math.min(slidesPerViewSM + 0.2, slideCount),
            allowTouchMove: slideCount > slidesPerViewSM,
          },
          768: {
            slidesPerView: Math.min(slidesPerViewMD + 0.2, slideCount),
            allowTouchMove: slideCount > slidesPerViewMD,
          },
          992: {
            slidesPerView:Math.min(slidesPerViewLG + 0.2, slideCount),
            freeMode: freeModeOnLg,
            allowTouchMove: slideCount > slidesPerViewLG,
          }
        }
      });

    }
  }
}
