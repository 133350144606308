export default function (data) {
 var productSlides = {};
  return {
    currentTab: null,
    swiper: null,
    productSlides: {},

    setCurrentTab: function (currentTab, index) {
      this.currentTab = currentTab;
      if (this.swiper) {
        // @ts-ignore
        if (window.matchMedia('(max-width: 450px)') && index < 2) {
          index = 0;
        } else if (!window.matchMedia('(max-width: 450px)') && index <= 3) {
          index = 0;
        }
        // @ts-ignore
        this.swiper.slideTo(index);
        // @ts-ignore
        if (!productSlides[currentTab]) {
          this.initSlideProducts(currentTab)
        }
      }
    },

    getCurrentTab: function () {
      return this.currentTab;
    },
    initSlideProducts: async function(currentTab){
      const { Swiper, Navigation, Lazy, Scrollbar } = await import('swiper');
      Swiper.use([Navigation, Lazy, Scrollbar]);

      let el = document.querySelector('#'+currentTab+' .swiper-container');

      let scrollbarEl = document.querySelector('#'+currentTab+' .swiper-scrollbar');

      let autoplay;
      let loop; //if autoplay true, enable loop

      autoplay = false;
      loop = false;



      // @ts-ignore
      productSlides[currentTab] = new Swiper(el, {
        loop: loop,
        observer: true,
        observeParents: true,
        slidesPerView: 2,
        spaceBetween: 20,
        preloadImages: false,
        autoplay: autoplay,
        allowTouchMove: true,
        freeMode: true,
        scrollbar: {
          el: scrollbarEl,
          draggable: true,
        },
        lazy: {
          //  tell swiper to load images before they appear
          loadPrevNext: true,
          // amount of images to load
          loadPrevNextAmount: 2,
        },
        slideToClickedSlide: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: document.querySelector('#'+currentTab+' .swiper-button-next'),
          prevEl: document.querySelector('#'+currentTab+' .swiper-button-prev')
        },
        breakpoints: {
          480: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          }
        }
      });

    },

    initSlideTabs: async function (currentTab, $refs) {

      const {Swiper, Navigation} = await import('swiper');
      Swiper.use([Navigation]);

      let el = $refs.swiper;
      let next = $refs.next;
      let prev = $refs.prev;

      let autoplay;
      let loop; //if autoplay true, enable loop

      autoplay = false;
      loop = false;
      // @ts-ignore
      this.swiper = new Swiper(el, {
        loop: loop,
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        preloadImages: false,
        autoplay: autoplay,
        watchSlidesProgress: true,
        freeMode: true,
        centerInsufficientSlides: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
        }
      });
      this.setCurrentTab(currentTab, 0);
    }
  }
}
