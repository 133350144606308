
let menu = null;

export default function (data: any) {
  return {
    menu: null,
    initMenu: function () {
      const self = this;
      window.addEventListener('init_delayed_scripts', function () {
        self.getMenu();
      });
    },
    getMenu: async function () {
      if (menu === null) {
        let homepageTitle = 'Startseite'
        // @ts-ignore
        if (window.TRANSLATIONS['Home Page']) {
          // @ts-ignore
          homepageTitle = window.TRANSLATIONS['Home Page'];
        }
        let searchTitle = 'Suche'
        // @ts-ignore
        if (window.TRANSLATIONS['Search']) {
          // @ts-ignore
          searchTitle = window.TRANSLATIONS['Search'];
        }
        // @ts-ignore
        menu = false;
        const Mmenu = await import('mmenu-js/src/mmenu');
        const element = document.getElementById('mm-menu');
        let bottomHtml = '';
        const bottomEl = document.getElementById('mm-menu-bottom');
        if (bottomEl) {
          bottomHtml = bottomEl.outerHTML;
        }
        if (element) {
          menu = new Mmenu.default(element, {
            scrollBugFix: {
              // scroll bug fix options
            },
            "extensions": [
              "position-right"
            ],
            navbar: {
              title: homepageTitle
            },
            "navbars": [
              {
                "position": "top",
                "content": [
                  "<form class=\"mm-searchfield\" action=\"/catalogsearch/result\" method=\"get\"><div class=\"mm-searchfield__input\"><input class=\"\" type=\"text\" autocomplete=\"off\" placeholder=\""+searchTitle+"...\" aria-label=\"Suche...\" name=\"q\"></div></form>",
                  "close"
                ]
              },
              {
                "position": "bottom",
                "content": [
                  bottomHtml
                ]
              }
            ],
            "offCanvas": true
          }, {
            scrollBugFix: {
              // scroll bug fix configuration
            },
            // configuration
            offCanvas: {
              pageSelector: ".page-wrapper"
            }
          });
        }
        const closeBtn = document.querySelector('.mm-btn.mm-btn--close');
        if (closeBtn instanceof HTMLElement) {
          closeBtn.id = '';
          closeBtn.addEventListener('click', this.closeMenu.bind(this));
        }
        const overlay = document.querySelector('.mm-wrapper__blocker.mm-slideout');
        if (overlay instanceof HTMLElement) {
          overlay.id = '';
          overlay.addEventListener('click', this.closeMenu.bind(this));
        }
      }
      return menu;
    },
    openMenu: async function () {
      const menu = await this.getMenu();
      if (menu) {
        // @ts-ignore
        menu.API.open();
      }
    },
    closeMenu: async function () {
      const menu = await this.getMenu();
      if (menu) {
        // @ts-ignore
        menu.API.close();
      }
    }
  }
}
