
export default function(data){

    return {
        swiper: null,
        swiperContent: null,
        swipeTo: function(currentIndex) {
          if (this.swiper) {
            setTimeout(() => {
              // @ts-ignore
              this.swiper.slides.forEach(function(element, index) {
                element.classList.remove('active');
                element.classList.remove('future');
                if (index > currentIndex) {
                  element.classList.add('future');
                }
              });
              // @ts-ignore
              this.swiper.slides[currentIndex].classList.add('active');
            },300);
            // @ts-ignore
            this.swiperContent.slideTo(currentIndex);
            return true;
          }
          return false;
        },
        initSteps: async function($refs){

            const { Swiper, Navigation, Lazy, Mousewheel } = await import('swiper');
            Swiper.use([Navigation, Lazy, Mousewheel]);


            let loop = false; //if autoplay true, enable loop
            // @ts-ignore
            this.swiperContent = new Swiper($refs.swiperContent, {
              loop: false,
              slidesPerView: 1,
              spaceBetween: 1,
              preloadImages: false,
              watchSlidesProgress: true,
              mousewheel: false,
              navigation: {
                nextEl: $refs.contentNext,
                prevEl: $refs.contentPrev,
              }
            });

            let el = $refs.swiper;
            // @ts-ignore
            this.swiper = new Swiper(el, {
                observer: true,
                observeParents: true,
                slidesPerView: 'auto',
                loop: loop,
                preloadImages: false,
                autoplay: false,
                freeMode: true,
                slideToClickedSlide: true,
                centeredSlides: true,
                breakpoints: {
                  1024: {
                    centeredSlides: false
                  }
                }
            });
            // @ts-ignore
            //this.swiper.params.control = this.swiperContent;
            // @ts-ignore
            //this.swiperContent.params.control = this.swiper;
            // @ts-ignore
            this.swiperContent.on('slideChange', function () {
                // @ts-ignore
                var currentIndex = this.swiperContent.activeIndex;
                // @ts-ignore
                this.swiper.slides[currentIndex].click();
                // @ts-ignore
                this.swiper.slideTo(currentIndex, 300);
            }.bind(this));
            // @ts-ignore
            this.swiper.on('slideChange', function () {
              // @ts-ignore
              var currentIndex = this.swiper.activeIndex;
              setTimeout(() => {

                // @ts-ignore
                this.swiper.slides.forEach(function(element, index) {
                  element.classList.remove('active');
                  element.classList.remove('future');
                  if (index > currentIndex) {
                    element.classList.add('future');
                  }
                });
                // @ts-ignore
                this.swiper.slides[currentIndex].classList.add('active');
                // @ts-ignore
                this.swiper.update();
              }, 100);
            }.bind(this));

        }
    }
}
