export default function (data) {
  const {autocompleteUrl} = data;
  let controller: AbortController;
  return {
    query: "",
    results: [],
    showHover: false,
    count: 0,
    searchFn: function () {
      const input = this.query;
      const url = `${autocompleteUrl}?ajax=1&form_key=` + window['FORM_KEY'] + `&q=${encodeURI(input)}`

      if (input.length < 3) {
        this.results = [];
        return;
      }
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();

      fetch(url, {
        method: 'get',
        signal: controller.signal
      }).then(response => response.json()).then(data => {
        this.results = data.results
        this.count = data.count
      }).catch(err => {
          console.warn(err);
        });
    },
    reloadImages: function() {
      // @ts-ignore
      window.lazyLoadInstance.update();
    }
  }
}
