
export default function(){

    return {
        initSlideStage: async function($refs){

            const { Swiper, Navigation, Lazy, Pagination, Autoplay } = await import('swiper');
          Swiper.use([Navigation, Lazy, Pagination, Autoplay]);

            const el = $refs.swiper;
            const prev = $refs.prev;
            const next = $refs.next;
            const pagination = $refs.pagination;

            let autoplay;
            let loop; //if autoplay true, enable loop

            if(el.getAttribute('data-autoplay') != 'false'){
                autoplay = { delay: el.getAttribute('data-autoplay') * 1000 };
                loop = true;
            } else {
                autoplay = false;
                loop = true;
            }

            const swiper = new Swiper(el, {
                loop: loop,
                observer: true,
                observeParents: true,
                slidesPerView: 1,
                spaceBetween: 0,
                preloadImages: false,
                autoplay: autoplay,
                lazy: {
                    loadPrevNext: true,
                },
                pagination: {
                    el: pagination
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                }
            });

        }
    }
}
