// adding jquery...
const $ = require('jquery');
import lazyload from "@frontend/utilities/lazyload";
import smoothscroll from 'smoothscroll-polyfill';
import "@frontend/utilities/varienform";
import "@frontend/utilities/validation/standard-validations";
import {ScrollSpy, Dropdown} from 'bootstrap';

window['jQuery'] = $;
window['$'] = $;
window['$j'] = $;
window['$scrollspy'] = ScrollSpy;
window['$dropdown'] = Dropdown;
(async function() {
  await import('@fancyapps/fancybox');
  $.fancybox.defaults.mobile.clickSlide = function() {
    return "close"
  }
  $.fancybox.defaults.mobile.clickContent = function() {
    return "close"
  }

  function getFancyBoxSetup(type) {
    return {
      maxWidth:  800,
      maxHeight: 600,
      width: '70%',
      height: '70%',
      type: type,
      touch: type !== 'ajax',
      afterShow: function(){
        // fancybox is open, run myFunct()
        lazyload.update();
        const element = document.querySelector('.fancybox-slide.fancybox-slide--html');
        if (element) {
          setTimeout(function() {
            element.scrollTo({top: 0});
          },250);

        }
      },
      mobile: {
        clickContent : "false",
        clickSlide : "false"
      }
    };
  }
  $(document).ready(function(){
    $.fancybox.defaults.mobile.dblclickContent = null;
    $.fancybox.defaults.mobile.dblclickSlide = null;
    $.fancybox.defaults.animationDuration = 100;
    $.fancybox.defaults.backFocus = false;
    $.fancybox.defaults.afterShow = function(){
      lazyload.update();
    };
    $.fancybox.defaults.beforeShow = function(){
      $.fancybox.getInstance().update();
    };
    $("a.lightboxImage").fancybox(getFancyBoxSetup('image'));
    $("a.lightboxAjax").fancybox(getFancyBoxSetup('ajax'));
  });
})();



import init from '@frontend/init';
import Account from '@frontend/components/Account';
import Cart from '@frontend/components/Cart';
import Filters from '@frontend/components/Filters';
import Header from '@frontend/components/Header';
import MegaDropDown from '@frontend/components/MegaDropDown';
import Messages from '@frontend/components/Messages';
import MobileMenu from '@frontend/components/MobileMenu';
import Modal from '@frontend/components/Modal';
import ShoppingBar from '@frontend/components/ShoppingBar';
import SlideProductMedia from '@frontend/components/SlideProductMedia';
import Search from '@frontend/components/Search';
import SlideCategories from '@frontend/components/SlideCategories';
import SlideImages from '@frontend/components/SlideImages';
import SlideProducts from '@frontend/components/SlideProducts';
import SlideStage from '@frontend/components/SlideStage';
import SlideTabs from '@frontend/components/SlideTabs';
import Wishlist from '@frontend/components/Wishlist';
import ConfigurableProduct from '@frontend/components/ConfigurableProduct';
import SearchMobile from '@frontend/components/SearchMobile';
import Price from '@frontend/components/Price';
import RelatedProducts from '@frontend/components/RelatedProducts';
import AddToCart from '@frontend/components/AddToCart';
import AddToCartAjax from '@frontend/components/AddToCartAjax';
import Accordion from '@frontend/components/Accordion';
import UpdateQuantity from '@frontend/components/UpdateQuantity';
import SearchWissen from '@frontend/components/SearchWissen';
import Chat from '@frontend/components/Chat';
import RelatedSupplyCategories from '@frontend/components/RelatedSupplyCategories';
import ContentSlider from '@frontend/components/ContentSlider';
import ContentTabs from '@frontend/components/ContentTabs';
import Ticker from '@frontend/components/Ticker';
import SlideSteps from '@frontend/components/SlideSteps';
import SlideAdvantages from '@frontend/components/SlideAdvantages';

import RegionData from "@frontend/components/RegionData";


import { initPrivateData } from "@frontend/utilities/private-data";

import "@frontend/utilities/form-key";
import "@frontend/utilities/lightbox";
import "@frontend/utilities/data-post";
import "@frontend/utilities/matchheight";
import "@frontend/utilities/translate";

init({
    Account,
    Cart,
    Filters,
    Header,
    MegaDropDown,
    Messages,
    MobileMenu,
    Modal,
    ShoppingBar,
    SlideProductMedia,
    Search,
    SlideCategories,
    SlideImages,
    SlideProducts,
    SlideStage,
    SlideTabs,
    Wishlist,
    ConfigurableProduct,
    RelatedSupplyCategories,
    Price,
    SearchMobile,
    RelatedProducts,
    AddToCart,
    AddToCartAjax,
    Accordion,
    UpdateQuantity,
    RegionData,
    SearchWissen,
    Chat,
    ContentSlider,
    ContentTabs,
    Ticker,
    SlideSteps,
    SlideAdvantages
});

lazyload.update();
initPrivateData();
smoothscroll.polyfill();

