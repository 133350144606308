import {getCookie, setCookie } from "@frontend/utilities/cookie";

export default function (data) {
  return {
    open: false,
    visible: true,
    bubbleVisible: true,
    initChat: function () {
      this.bubbleVisible = getCookie('chat_bubble_closed') !== '1';
      window.addEventListener('init_zendesk', function () {
        // @ts-ignore
        $zopim(function() {
          const html = document.documentElement // returns the html tag
          const locale = html.getAttribute('lang');
          if (locale) {
            // @ts-ignore
            $zopim.livechat.setLanguage(locale);
          }

          // @ts-ignore
          $zopim.livechat.hideAll();
          // @ts-ignore
          $zopim.livechat.window.onHide(function() {
            // @ts-ignore
            $zopim.livechat.hideAll();
          });

        });
      });

      const self = this;
      const observer = new IntersectionObserver(function(entries) {
        self.visible = !entries[0].isIntersecting;
        // @ts-ignore
        jQuery('#MyCustomTrustbadge').toggle(self.visible);
      }, { threshold: [0.1]});
      const footer = document.querySelector("footer.footer");
      if (footer instanceof HTMLElement) {
        observer.observe(footer);
      }
      window['openZfChat'] = self.openOverlay.bind(self);
    },
    openOverlay: function () {
      this.open = true;
      // @ts-ignore
      if ($zopim) {
        // @ts-ignore
        $zopim(function() {
          // @ts-ignore
          $zopim.livechat.hideAll();
        });
      }

      const ts = document.querySelector("[id^='trustbadge-container-']");
      if (ts instanceof HTMLElement) {
        ts.style.display = 'none';
      }
    },
    closeBubble: function () {
      this.bubbleVisible = false;
      setCookie('chat_bubble_closed', '1')
    },
    closeOverlay: function () {
      this.open = false;
      const ts = document.querySelector("[id^='trustbadge-container-']");
      if (ts instanceof HTMLElement) {
        ts.style.display = 'block';
      }
    },
    openChat: function () {
      this.closeOverlay();
      // @ts-ignore
      if ($zopim) {
        // @ts-ignore
        $zopim(function() {
          // @ts-ignore
          $zopim.livechat.window.show();
        });
      }

    }
  }

}

