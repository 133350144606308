export default function () {
  return {

    initObservers: {
      ['@add-to-cart-successful.window']: function ($event) {
        // @ts-ignore
        var id = jQuery('[data-product-add-to-car-overy-id]').data('product-add-to-car-overy-id');
        if (!id) {
          return;
        }
        // @ts-ignore;
        jQuery.fancybox.open({
          // @ts-ignore;
          src  : window.BASE_URL+'checkout/cart/overlay?id='+id,
          type : 'ajax',

          opts: {
            maxWidth:  '770px',
            touch: false,
            clickSlide: function () {
              return "close"
            },
            backdropClick: true,
            clickOutside: true,
            fitToView: true,
            width: '90%',
            height: 'auto',
            padding: 0,
            baseClass: 'cart-modal',
            autoSize: false,
            scrolling: 'no',
            autoFocus: false,
            focusBack: false,
            overlay: {
              closeClick: true,
            },
            mobile: {
              clickContent: null
            }
          }
        });
      }
    }
  }
}
