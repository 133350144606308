
export default function(data){

    return {
        initSlideImages: async function($refs){

            const { Swiper, Navigation, Lazy } = await import('swiper');
            Swiper.use([Navigation, Lazy]);

            let el = $refs.swiper;
            let next = $refs.next;
            let prev = $refs.prev;

            let autoplay;
            let loop; //if autoplay true, enable loop

            if(el.getAttribute('data-autoplay') != 'false'){
                autoplay = { delay: el.getAttribute('data-autoplay') * 1000 };
                loop = true;
            } else {
                autoplay = false;
                loop = false;
            }

            const swiper = new Swiper(el, {
                observer: true,
                observeParents: true,
                loop: loop,
                slidesPerView: 3,
                spaceBetween: 20,
                autoplay: autoplay,
                preloadImages: false,
                lazy: {
                  //  tell swiper to load images before they appear
                  loadPrevNext: true,
                  // amount of images to load
                  loadPrevNextAmount: 2,
                },
                breakpoints: {
                    480: {
                        slidesPerView: 6
                    },
                    768: {
                        slidesPerView: 8
                    },
                    1280: {
                        slidesPerView: 12
                    }
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                }
            });

        }
    }
}

