export default function (data) {
  return {
    currentTab: null,
    swiper: null,
    productSlides: {},

    setCurrentTab: function (currentTab, index) {
      this.currentTab = currentTab;
      if (this.swiper) {
        if (window.matchMedia('(max-width: 450px)') && index < 2) {
          index = 0;
        } else if (!window.matchMedia('(max-width: 450px)') && index < 3) {
          index = 0;
        }
        // @ts-ignore
        this.swiper.slideTo(index);
      }
    },

    getCurrentTab: function () {
      return this.currentTab;
    },

    initContentTabs: async function (currentTab, $refs) {
      this.currentTab = currentTab;
      const {Swiper, Navigation} = await import('swiper');
      Swiper.use([Navigation]);

      let el = $refs.swiper;
      let next = $refs.next;
      let prev = $refs.prev;

      let autoplay;
      let loop; //if autoplay true, enable loop

      autoplay = false;
      loop = false;
      // @ts-ignore
      this.swiper = new Swiper(el, {
        loop: loop,
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        preloadImages: false,
        autoplay: autoplay,
        watchSlidesProgress: true,
        freeMode: true,
        centerInsufficientSlides: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
        }
      });
      this.setCurrentTab(currentTab, 0);
    }
  }
}
