export default function () {
  return {

    currentTab: null,

    toggleTab: function(currentTab) {

      if(this.currentTab == currentTab) {
        this.currentTab = null;
      }
      else {
        this.currentTab = currentTab;
      }
    },

    setCurrentTab: function(currentTab) {
      this.currentTab = currentTab;
    },

    getCurrentTab: function() {
      return this.currentTab;
    }
  }
}
