
export default function(data){

    return {
        swiper: null,
        swiperContent: null,

        initSlider: async function($refs){

            const { Swiper, Navigation, Lazy, Mousewheel } = await import('swiper');
            Swiper.use([Navigation, Lazy, Mousewheel]);
            let loop = false; //if autoplay true, enable loop
            // @ts-ignore
            this.swiperContent = new Swiper($refs.swiper, {
              loop: false,
              slidesPerView: 1,
              spaceBetween: 1,
              preloadImages: false,
              watchSlidesProgress: true,
              mousewheel: true,
              navigation: {
                nextEl: $refs.next,
                prevEl: $refs.prev,
              }
            });

        }
    }
}
