export default function() {

    return {

        qty : 1,
        disabled: false,
        isStared: false,

        initObservers: {
            ['@add-to-cart-start.window']: function ($event) {
              // @ts-ignore;
              this.disabled = true;
            },
            ['@add-to-cart-finish.window']: function ($event) {
              setTimeout(() => {
                // @ts-ignore;
                this.disabled = false;
              }, 200);
            },
            ['@updateQuantity.window']: function($event) {
                const data = $event.detail;
                if (!data) {
                    return;
                }

                this['qty'] = data.quantity;
            },
            ['@updateAddToCartButtonState.window']: function($event) {
                const data = $event.detail;
                if (!data) {
                    return;
                }

                this['disabled'] = data.disabled;
            }
        },

        initShoppingBar: function($refs, selectorHideShoppingBar : string = '.btn--cart', data = {}){
            // @ts-ignore
            if (data.qty > 0) {
              // @ts-ignore
              this.qty = data.qty;
            }
            function domReady(fn) {
              // If we're early to the party
              document.addEventListener("DOMContentLoaded", fn);
              // If late; I mean on time.
              if (document.readyState === "interactive" || document.readyState === "complete" ) {
                fn();
              }
            }
            domReady(() => {
              window.addEventListener(
                "scroll",
                () => {
                  var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
                  if (scrollTop == 0) {
                    disableShoppingBar();
                  }
                  if (scrollTop > 10 && scrollTop < 350) {
                    enableShoppingBar();
                  }

                },
                { passive: true }
              );
            })

            const self = this;
            let observer = new IntersectionObserver(onIntersection);

            function disableShoppingBar() {
              const element = document.querySelector('.shopping-bar');
              let zfChat = document.querySelector('.zf-chat');
              if(element instanceof HTMLElement){
                element.classList.remove('shopping-bar--active');
                document.body.classList.remove("shopping-bars-visible");
                if (zfChat instanceof HTMLElement) {
                  zfChat.style.bottom = '0';
                }
              }
            }

            function enableShoppingBar() {
              const element = document.querySelector('.shopping-bar');
              if(element){
                element.classList.add('shopping-bar--active');
                document.body.classList.add("shopping-bars-visible");
                let zfChat = document.querySelector('.zf-chat');
                if (zfChat instanceof HTMLElement) {
                  zfChat.style.bottom = element.clientHeight + 'px';
                  setTimeout(() => {
                    if (zfChat instanceof HTMLElement && element) {
                      zfChat.style.bottom = element.clientHeight + 'px';
                    }

                  }, 400);
                }
              }
            }

            function onIntersection(entries) {
                entries.forEach(function (entry) {
                    var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
                    if (scrollTop == 0) {
                      disableShoppingBar();
                      return;
                    }
                    if (entry.intersectionRatio > 0) {
                      disableShoppingBar();
                    } else {
                      enableShoppingBar();
                    }
                });
            }
            let sb = document.querySelectorAll(selectorHideShoppingBar);

            Array.prototype.forEach.call(sb, function(el, i){
              observer.observe(el);
            });
            disableShoppingBar();

            window.addEventListener('shopping_bar_init', function () {
              sb = document.querySelectorAll(selectorHideShoppingBar);
              Array.prototype.forEach.call(sb, function(el, i){
                observer.observe(el);
              });
              disableShoppingBar();
            });

          const footerObserver = new IntersectionObserver(function(entries) {
            if(entries[0].isIntersecting) {
              let element = document.querySelector('.shopping-bar');
              let zfChat = document.querySelector('.zf-chat');
              if(element){
                element.classList.remove('shopping-bar--active');
                document.body.classList.remove("shopping-bars-visible");

                if (zfChat instanceof HTMLElement) {
                  zfChat.style.bottom = '0';

                }
              }
            }
          }, { threshold: [0.1]});
          const footer = document.querySelector("footer.footer");
          if (footer instanceof HTMLElement) {
            footerObserver.observe(footer);
          }
        },

        onQtyChange: function() {

            var event = new CustomEvent('updateQuantity', {
                detail : {
                    quantity : this.qty
                }
            });

            window.dispatchEvent(event);
        },

        incQuantity: function() {

            this.qty++;
            this.onQtyChange();
        },

        decQuantity: function() {

            if(this.qty > 1) {
                this.qty--;
                this.onQtyChange();
            }
        },

        addToCart: function() {
            this.disabled = true;
            var event = new CustomEvent('addProductToCart');
            window.dispatchEvent(event);
        }
    }
}
