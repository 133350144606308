

export default function(data){

    return {
        initSlideProducts: async function($refs){
            const { Swiper, Navigation, Lazy, Scrollbar, FreeMode } = await import('swiper');
            Swiper.use([Navigation, Lazy, Scrollbar, FreeMode]);

            let el = $refs.swiper;
            let scrollbarEl = $refs.scrollbar;
            let swiper;

            let autoplay;
            let loop; //if autoplay true, enable loop

            if(el.getAttribute('data-autoplay') != 'false'){
                autoplay = { delay: el.getAttribute('data-autoplay') * 1000 };
                loop = true;
            } else {
                autoplay = false;
                loop = false;
            }

            if(data === 1){
                swiper = new Swiper(el, {
                    observer: true,
                    observeParents: true,
                    loop: loop,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    preloadImages: false,
                    autoplay: autoplay,
                    freeMode: true,
                    allowTouchMove: true,
                    scrollbar: {
                      el: scrollbarEl,
                      draggable: true,
                    },
                    lazy: {
                      //  tell swiper to load images before they appear
                      loadPrevNext: true,
                      // amount of images to load
                      loadPrevNextAmount: 2,
                    },
                    slideToClickedSlide: false,
                    watchSlidesProgress: true,
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });

            } else if(data === 2) {
                swiper = new Swiper(el, {
                    observer: true,
                    observeParents: true,
                    loop: loop,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    preloadImages: false,
                    autoplay: autoplay,
                    freeMode: true,
                    allowTouchMove: true,
                    scrollbar: {
                      el: scrollbarEl,
                      draggable: true,
                    },
                    lazy: {
                        loadPrevNext: true,
                    },
                    slideToClickedSlide: false,
                    watchSlidesProgress: true,
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });

            } else if(data === 6) {
                swiper = new Swiper(el, {
                    observer: true,
                    observeParents: true,
                    loop: loop,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    preloadImages: false,
                    autoplay: autoplay,
                    freeMode: true,
                    allowTouchMove: true,
                    scrollbar: {
                      el: scrollbarEl,
                      draggable: true,
                    },
                    lazy: {
                        loadPrevNext: true,
                    },
                    slideToClickedSlide: false,
                    watchSlidesProgress: true,
                    breakpoints: {
                        480: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 4
                        },
                        992: {
                            slidesPerView: 6
                        }
                    },
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });
            } else if(data === 4) {
                swiper = new Swiper(el, {
                    observer: true,
                    observeParents: true,
                    loop: loop,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    preloadImages: false,
                    autoplay: autoplay,
                    freeMode: {
                      enabled: true,
                      minimumVelocity: 0.001,
                      sticky: false
                    },
                    allowTouchMove: true,
                    scrollbar: {
                      el: scrollbarEl,
                      draggable: true,
                    },
                    lazy: {
                        loadPrevNext: true,
                    },
                    breakpoints: {
                        480: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 3
                        },
                        992: {
                            slidesPerView: 4
                        }
                    },
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });
            }
        }
    }
}

