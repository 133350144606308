
export default function () {
    return {
        header: null,
        initHeader: function($refs, $nextTick){
            this.header = $refs.header;
            document.addEventListener('scroll', this.onScroll.bind(this));
            document.addEventListener('touchend', this.onScroll.bind(this));

        },
        onScroll: function () {
          const scrollTop = window.pageYOffset;
          if (!this.header) {
            return;
          }

          const headerHeight = (this.header! as HTMLElement).offsetHeight;
          if (scrollTop > 120) {
            document.body.classList.add('fixed-header');
          } else {
            document.body.classList.remove('fixed-header');
          }
        },
        onResize: function($refs){}
    }
}
