export default function(){
    return {
        items : {},
        qty : {},
        getQuantity: function(id)
        {
            if(typeof this.qty[id] === "undefined") {
                this.qty[id] = 1;
            }

            return this.qty[id];
        },
        updateRelatedField: function()
        {
            var relatedField = '';

            for (const [id, status] of Object.entries(this.items)) {

                if(!status) return;

                // @ts-ignore
                for(var i = 0; i < parseInt(this.qty[id]); i++) {
                    relatedField += id + ",";
                }
            }

            return relatedField;
        },
        increaseQty: function(id)
        {
            this.qty[id]++;
        },
        decreaseQty: function(id)
        {
            if(this.qty[id] > 1) this.qty[id]--;
        }
    }
}
