interface TickerInterface {
    tickerContainer: HTMLElement|null,
    headlineContainer: HTMLElement|null,
    headlineElements: HTMLElement|null,
    headlines: [],
    headlineTagMap: Object,
    outerTimeoutId: number,
    innerTimeoutId: number,
    currentHeadline: number,
    currentHeadlinePosition: number,
    firstOuterTick: Boolean,
    firstInnerTick: Boolean,
    allowedTags: string[],
    opts: {
        random: boolean,
        itemSpeed: number,
        cursorSpeed: number,
        pauseOnHover: Boolean,
        finishOnHover: Boolean,
        cursorOne: String,
        cursorTwo: String,
        fade: Boolean,
        fadeInSpeed: number,
        fadeOutSpeed: number,
    }
    initTicker: Function,
    outerTick: Function,
    innerTick: Function,
    tick: Function,
    getCurrentTick: Function,
    advance: Function,
    shuffleArray: Function,
    stripTags: Function,
    locateTags: Function
}

export default function() {
    return {
        tickerContainer: null,
        headlineContainer: null,
        headlineElements: null,
        headlines: [],
        headlineTagMap: {},
        outerTimeoutId: 0,
        innerTimeoutId: 0,
        currentHeadline: 0,
        currentHeadlinePosition: 0,
        firstOuterTick: true,
        firstInnerTick: true,
        allowedTags: ['a', 'b', 'strong', 'span', 'i', 'em', 'u'],
        opts: {
            random:        false, // Whether to display ticker items in a random order
            itemSpeed:     4000,  // The pause on each ticker item before being replaced
            cursorSpeed:   0,    // Speed at which the characters are typed
            pauseOnHover:  true,  // Whether to pause when the mouse hovers over the ticker
            finishOnHover: true,  // Whether or not to complete the ticker item instantly when moused over
            cursorOne:     '_',   // The symbol for the first part of the cursor
            cursorTwo:     '-',   // The symbol for the second part of the cursor
            fade:          false,  // Whether to fade between ticker items or not
            fadeInSpeed:   600,   // Speed of the fade-in animation
            fadeOutSpeed:  300    // Speed of the fade-out animation
        },
        initTicker: function($refs){

            this.tickerContainer = $refs.tickerContainer as HTMLElement;
            // @ts-ignore
            this.headlineElements = this.tickerContainer.querySelectorAll('li');

            if (this.opts.finishOnHover || this.opts.pauseOnHover) {
                // Setup monitoring hover state
                // @ts-ignore
                this.tickerContainer.classList.remove('hover');
                // @ts-ignore
                this.tickerContainer.addEventListener('hover', function() {
                    // @ts-ignore
                    this.tickerContainer.classList.toggle('hover');
                }.bind(this));
            }

            // Save all the headline text
            let h, l;
            // @ts-ignore
            this.headlineElements.forEach((this, element, index) => {
                h = this.stripTags(element.innerHTML, this.allowedTags); // Strip all but the allowed tags
                // @ts-ignore
                l = this.locateTags(h); // Get the locations of the allowed tags
                // @ts-ignore
                h = this.stripTags(h); // Remove all of the HTML tags from the headline
                // @ts-ignore
                this.headlines.push(h); // Add the headline to the headlines list
                // @ts-ignore
                this.headlineTagMap[this.headlines.length - 1] = l; // Associate the tag map with the headline
            });

            // Randomize?
            if (this.opts.random) this.shuffleArray(this.headlines);

            // Now delete all the elements and add the headline container
            //let tmp = this.tickerContainer.querySelectorAll('ul').after('<div></div>').remove();
            let tickerList = this.tickerContainer.querySelector('ul');
            if (!tickerList) {
              return;
            }
            let div = document.createElement('div');
            div.setAttribute('id','TickerHeadlineContainer');
            div.setAttribute('class','d-md-none text-center');
            if (!tickerList.parentNode) {
              return;
            }
            tickerList.parentNode.insertBefore( div, tickerList.nextSibling );
            // @ts-ignore
            this.headlineContainer = document.getElementById('TickerHeadlineContainer');

            this.outerTick();
        },
        outerTick: function(){
            this.firstInnerTick = true;

            if (this.firstOuterTick) {
                this.firstOuterTick = false;
                this.innerTick();
                return;
            }
            let that = this;
            this.outerTimeoutId = window.setTimeout(function (this) {
                // @ts-ignore
                if (that.opts.pauseOnHover && that.tickerContainer.classList.contains('hover')) {
                    // User is hovering over the ticker and pause on hover is enabled
                    clearTimeout(this.innerTimeoutId);
                    that.outerTick();
                    return;
                }

                that.innerTick();
            }, this.opts.itemSpeed);
        },
        innerTick: function(){
          let mql = window.matchMedia('(min-width: 768px)');
          if(mql.matches){
            const that = this;
            this.innerTimeoutId = window.setTimeout(function() {
              that.innerTick();
            }, 2000);
            return;
          }

          if (this.firstInnerTick) {
                this.firstInnerTick = false;
                this.tick();
                return;
            }
            // @ts-ignore
            if (this.currentHeadlinePosition > this.headlines[this.currentHeadline].length) {
                this.advance();
                return;
            }

            // @ts-ignore
            if (this.opts.finishOnHover && this.opts.pauseOnHover && this.tickerContainer.classList.contains('hover') && this.currentHeadlinePosition <= this.headlines[this.currentHeadline].length) {
                // Let's quickly complete the headline
                // This is outside the timeout because we want to do this instantly without the pause

                // Update the text
                // @ts-ignore
                this.headlineContainer.innerHTML = this.getCurrentTick();
                // Advance our position
                this.currentHeadlinePosition += 1;

                this.innerTick();
                return;
            }
            else {
                // Handle as normal
                let that = this;
                this.innerTimeoutId = window.setTimeout(function() {
                    // @ts-ignore
                    if (that.opts.pauseOnHover && that.tickerContainer.classList.contains('hover')) {
                        // User is hovering over the ticker and pause on hover is enabled
                        clearTimeout(that.innerTimeoutId);
                        that.innerTick();
                        return;
                    }
                    that.tick();
                    that.advance();
                }, this.opts.cursorSpeed);
            }
        },
        tick: function(){
            // Now let's update the ticker with the current tick string
            if (this.currentHeadlinePosition === 0 && this.opts.fade) {
                clearTimeout(this.innerTimeoutId);
            }
            else {
                // Update the text
                // @ts-ignore
                this.headlineContainer.innerHTML = this.getCurrentTick();
                // Advance our position
                this.currentHeadlinePosition += 1;
                clearTimeout(this.innerTimeoutId);
                this.innerTick();
            }
        },
        getCurrentTick: function(){
            let cursor, i, j, location;
                switch (this.currentHeadlinePosition % 2) {
                    case 1:
                        cursor = this.opts.cursorOne;
                        break;
                    case 0:
                        cursor = this.opts.cursorTwo;
                        break;
                }

                // Don't display the cursor this was the last character of the headline
                // @ts-ignore
                if (this.currentHeadlinePosition >= this.headlines[this.currentHeadline].length) cursor = '';

                // Generate the headline
                let headline = '';
                let openedTags = [];
                for (i = 0; i < this.currentHeadlinePosition; i++) {
                    location = null;
                    // Check to see if there's meant to be a tag at this index
                    for (j = 0; j < this.headlineTagMap[this.currentHeadline].length; j++) {
                        // Find a tag mapped to this location, if one exists
                        if (this.headlineTagMap[this.currentHeadline][j] && this.headlineTagMap[this.currentHeadline][j].start === i) {
                            location = this.headlineTagMap[this.currentHeadline][j]; // It does exist!
                            break;
                        }
                    }

                    if (location) {
                        // Add the tag to the headline
                        headline += location.tag;

                        // Now deal with the tag for proper HTML
                        if (! location.selfClosing) {
                            if (location.tag.charAt(1) === '/') {
                                openedTags.pop();
                            }
                            else {
                                // @ts-ignore
                                openedTags.push(location.name);
                            }
                        }
                    }

                    // Add the character to the headline
                    headline += this.headlines[this.currentHeadline][i];
                }

                // Now close the tags, if we need to (because it hasn't finished with all the text in the tag)
                for (i = 0; i < openedTags.length; i++) {
                    headline += '</' + openedTags[i] + '>';
                }

                return headline + cursor;
        },
        advance: function(){
            // Advance headline and reset character position, if it's at the end of the current headline
            // @ts-ignore
            if (this.currentHeadlinePosition > this.headlines[this.currentHeadline].length) { // > here and not == because the ticker cursor takes an extra loop
                this.currentHeadline += 1;
                this.currentHeadlinePosition = 0;

                // Reset the headline and character positions if we've cycled through all the headlines
                if (this.currentHeadline == this.headlines.length) this.currentHeadline = 0;

                // STOP! We've advanced a headline. Now we just need to pause.
                clearTimeout(this.innerTimeoutId);
                clearTimeout(this.outerTimeoutId);
                this.outerTick();
            }
        },
        shuffleArray: function(array){
            for (let i = array.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                let temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        },
        stripTags: function(text, safeTags){
            safeTags = safeTags || [];
            let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/img;
            let comments = /<!--.*?-->/img;
            return text.replace(comments, '').replace(tags, function (a, b) {
                return safeTags.indexOf(b.toLowerCase()) !== -1 ? a : '';
            });
        },
        locateTags: function(text, tagList){
            tagList = tagList || [];
            let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/im;
            let selfClosing = /\/\s{0,}>$/m;
            let locations = [];
            let match, location;

            while ((match = tags.exec(text)) !== null) {
                if (tagList.length === 0 || tagList.indexOf(match[1]) !== -1) {
                    location = {
                        tag: match[0],
                        name: match[1],
                        selfClosing: selfClosing.test(match[0]),
                        start: match.index,
                        end: match.index + match[0].length - 1
                    };
                    // @ts-ignore
                    locations.push(location);

                    // Now remove this tag from the string
                    // so that each location will represent it in a string without any of the tags
                    text = text.slice(0, location.start) + text.slice(location.end + 1);

                    // Reset the regex
                    tags.lastIndex = 0;
                }
            }

            return locations;
        }
    } as TickerInterface
}

